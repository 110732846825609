<template>
    <div :id="'report-' + this.$parent.reportId + '-footnotes'" class="report-footnotes-wrapper" v-show="reportFootNotes && reportFootNotes.length !== 0">
        <div class="report-footnotes">
            <div v-for="(footnote, i) in reportFootNotes" :key="$parent.reportId + '-footnote-' + i" class="report-footnote">
                <vue-markdown :source="computeFootNote(footnote.FootnoteData ? footnote.FootnoteData : footnote)" :linkify="true" :html="true" :prerender="markupCleanup" :postrender="markdownCleanup"></vue-markdown>
            </div>
        </div>
        <button class="report-footnotes-expand-button" @click.prevent="expandFootnotes()">View <span v-if="!expanded">More</span><span v-else>Less</span></button>
    </div>
</template>

<script>
import VueMarkdown from 'vue3-markdown-it';

export default {
    name: 'footnotes',
    components: {
        VueMarkdown
    },
    props: [
        'reportFootNotes'
    ],
    data () {
        return {
            savedHeight: 0,
            expanded: false
        };
    },
    methods: {
        computeFootNote (data) {
            if (data.indexOf('/page/field-classification') !== -1) {
                data = data.replace('/page/field-classification', window.baseURL + '/page/field-classification')
            }
            data = data.replace(/\r\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
            data = data.replace(/\\r\\n/g, '<br>').replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
            return data.replace("* ", "*");
        },

        markdownCleanup (footnote) {
            return window.markdownCleanup(footnote.replace(/\*\\r\\n\\t/g, '<br>'));
        },

        markupCleanup (footnote) {
            return footnote.replace(/\*\s/, '*&nbsp;').replace(/\\r\\n\\t/g, '<br>');
        },

        expandFootnotes () {
            var footnoteContainer = $(this.$el),
                reportFootnotes = footnoteContainer.find('.report-footnotes');

            if (!this.expanded) {

                this.savedHeight = footnoteContainer.height();

                footnoteContainer.height(this.savedHeight);

                this.$nextTick(() => {
                    footnoteContainer.height(reportFootnotes.outerHeight())
                        .addClass('expanded')
                        .css('max-height', 'none');

                    this.expanded = true;
                });
            } else {
                footnoteContainer.height(this.savedHeight);

                this.$nextTick(() => {
                    footnoteContainer.removeClass('expanded');

                    this.expanded = false;
                });
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>

