<template>
    <div id="single-report-view">
        <report :reportId="reportId" ref="report" :LRPReport="LRPReport" :isExtramuralCategory="isExtramural"></report>
    </div>
</template>

<script>
import Report from '@/components/Report';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ReportFull',
    components: {
        Report
    },
    data () {
        return {
            msg: 'Welcome to Your Vue.js App',
            LRPReport: false,
            isExtramural: true,
            reportId: parseInt(this.$route.params.id, 10)
        };
    },
    computed: {
        ...mapGetters([
            'categoryList',
            'LRPFilters'
        ])
    },
    watch: {
        LRPFilters: function () {
            this.setupDefaultFilters();
        },
        $route () {
            if (this.$route.params.id) {
                this.checkIfLRPReport();
                this.reportId = parseInt(this.$route.params.id, 10);
                this.$refs.report.$forceUpdate();
            }
        }
    },
    created () {
        this.checkIfLRPReport();
    },
    methods: {
        ...mapActions([
            'setGlobalFilters'
        ]),
        checkIfLRPReport () {
            let $this = this,
                curCat;
            let selectedRep;
            this.categoryList.forEach(cat => {
                selectedRep = cat.Reports.filter(rep => rep.ReportId === $this.$route.params.id);
                curCat = cat;
                if (selectedRep.length === 0) {
                    cat.Categories.forEach(subCat => {
                        subCat.Reports.forEach(rep => {
                            if (rep.ReportId + '' === $this.$route.params.id) {
                                curCat = subCat;
                                selectedRep = rep;
                                return;
                            }
                        });
                    });
                }
            });
            if (selectedRep) {
                this.LRPReport = selectedRep.LRPReport ? selectedRep.LRPReport : false;
                this.isExtramural = selectedRep.LRPReport && curCat.CategoryName.toLowerCase().indexOf('intramural') === -1 ? true : false;
            }
        },
        setupDefaultFilters () {
            let currentFilters = {};
            for (let i = 0; i < this.LRPFilters.length; i++) {
                let element = this.LRPFilters[i];
                if (element.name === 'fy') {
                    currentFilters[element.data[0].name] = element.data[0].options[0];
                    currentFilters[element.data[1].name] = element.data[0].options[element.data[0].options.length - 1];
                } else {
                    currentFilters[element.name] = element.options[0].id;
                }
            }
            currentFilters['chartBy'] = 1;
            this.setGlobalFilters(currentFilters);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
