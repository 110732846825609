import { mapGetters } from 'vuex';
import EventBus from '@/js/event-bus.js';
import * as d3 from 'd3';

export default {
    props: [
    ],
    computed: {
        ...mapGetters([
            'LRPReportData',
            'globalFilters',
            'reportFilters'
        ])
    },
    data () {
        return {
            chartOptions:{
                containerInfo: {
                    top: 25, 
                    right: 20, 
                    bottom: 15, 
                    left: 25,  
                    width :  $(".chart-container").first().width()-20, 
                    graphWidth: $(".chart-container").first().width()-20,
                    graphHeight: 290,
                    height: 325, 
                    barPadding: .3,
                    axisTicks: 3, 
                    legendSpacing: 0,
                    graphTitleOffset: 30,
                    xAxisLabelHeight: 10,
                    xAxisLabelOffset: 15,
                    yAxisLabelOffset: 30,
                    fontSize: '14px',
                    axisFontSize: '12px',
                    graphSymbolSize: 100
                },
                exportContainerInfo: {
                    top: 35, 
                    right: 30, 
                    bottom: 25, 
                    left: 45,  
                    width :  1000,
                    totalHeight: 600,
                    height : 600, 
                    graphWidth: 1000,
                    graphHeight: 255,
                    barPadding: .3,
                    axisTicks: 3, 
                    legendSpacing: 0,
                    graphTitleOffset: 30,
                    xAxisLabelHeight: 10,
                    xAxisLabelOffset: 15,
                    yAxisLabelOffset: 30,
                    fontSize: '22px',
                    axisFontSize: '16px',
                    graphSymbolSize: 100
                }
            }
        };
    },
    mounted () {
        EventBus.$off('lrpChart:export');
        EventBus.$on('lrpChart:export', (reportId) => {
            this.exportChart(reportId);
        });
    },
    methods: {
        exportChart (reportId) {
        
            var svgDivExport,
            canvas,
            ctx,
            exportDiv = $('#export-div'),
            count = 1,
            yearData = '',
            filename,
            imageData,
            img,
            svgString,
            url,
            repInd = this.LRPReportData.findIndex((obj => obj.reportId === reportId)),
            reportData = repInd !== -1 ? this.LRPReportData[repInd].data : [],
            currentFilters =  Object.assign({}, this.globalFilters);

            let objIndex = this.reportFilters.findIndex((obj => obj.reportId === reportId));
            if (objIndex !== -1) {
                currentFilters = Object.assign({}, this.reportFilters[objIndex].filters);
            }
      
            let tabId = $("#report-" + reportId + " .tab-pane.active").attr("data-tabid");
            this.reDrawChart(reportId, tabId, true);
            if (currentFilters) {
                if (this.$parent.isExtramuralCategory) {
                    if (currentFilters.sFy) {
                        yearData = currentFilters.sFy + '-';
                    }

                    yearData += currentFilters.eFy;
                } else {
                    yearData += currentFilters.fiscalyear;
                }
            }

        if ($('#cnv').length === 0) {
            $('body').append('<canvas id="cnv" style="background-color: #fff;"></canvas>');
        }

        $('#cnv').empty().show();

        svgDivExport = $('#export-div');
        filename = reportData.reportName + '-' + yearData + '.png';
        function drawImages () {
            ctx.drawImage(img, 0, 0);
            imageData = canvas.toDataURL('image/png');

            $('body').append('<a href="' + imageData + '" download="' + filename + '" id="png-download" style="opacity: 0;">Download</a>');
            $('#png-download')[0].click();
            $('#png-download')[0].remove();
            $('#cnv').empty().hide();

            exportDiv.children('svg').empty();
        }

        if (svgDivExport.length > 0) {
            canvas = document.getElementById('cnv');

            canvas.height = 768;
            canvas.width = 1024;

            if (!window.isIE) {
                setTimeout(function () {
                    ctx = canvas.getContext('2d');

                    svgString = new XMLSerializer().serializeToString(document.getElementById('export-div').children[0]);

                    img = new Image();
                    url = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));

                    img.addEventListener('load', function () {
                        count--;
                        if (count === 0) {
                            drawImages();
                        }
                    });

                    img.src = url;
                }, 301);
            } else {
                setTimeout(function () {
                    canvg(canvas, exportDiv.children('.chart').html());

                    top.consoleRef = window.open('', 'Graph_Download', 'width=' + canvas.width + ', height=' + canvas.height + ', menubar=0, toolbar=1 , status=0, scrollbars=1, resizable=1');
                    top.consoleRef.document.writeln('<html><head><title>' + filename + '</title></head><body bgcolor=white onLoad=\'self.focus()\'><p>Please right click on image and select <b>Save picture as...</b> to save.</p><img src=\'' + canvas.toDataURL('image/png') + '\'/></body></html>');
                    top.consoleRef.document.close();

                    exportDiv.children('svg').empty();
                }, 600);
            }
        }

    }
    }
};
