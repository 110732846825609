<template>
    <div id="report-search" v-if="reportList">
        <button class="search-button search-button-mobile" @click.prevent="toggleMobileSearch()" v-show="isMobile">
            <i class="fas fa-search text-info"></i>
            <span class="sr-only">Show Search</span>
        </button>
        <input type="text" v-model="search" placeholder="Search Reports">
        <button class="search-button" @click.prevent="goToReport()">
            <i class="fas fa-search text-info"></i>
            <span class="sr-only">Search</span>
        </button>
        <div class="search-results-list" :class="results.length === 0 ? 'd-none' : ''">
            <ul>
                <li v-if="simpleHighlight" v-for="(report, index) in results" :key="'search-result-' + report.ReportId + '-' + index">
                    <button class="search-result-item" @click.prevent="loadReport(report.ReportId)" v-on:keydown="resultItemKeydown">
                        <span class="report-name" v-html="filters.highlightTerms(report.ReportName, search)"></span>
                        <span class="report-id">Report ID: <span v-html="filters.highlightTerms(report.ReportId, search)"></span></span>
                    </button>
                </li>

                <li v-if="!simpleHighlight" v-for="(report, index) in results" :key="'search-result-' + report.item.ReportId + '-' + index">
                    <button class="search-result-item" @click.prevent="loadReport(report.item.ReportId)" v-on:keydown="resultItemKeydown">
                        <span class="report-name" v-html="report.item.ReportNameHighlight"></span>
                        <span class="report-id">Report ID: <span v-html="report.item.ReportIdHighlight"></span></span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { isProxy, toRaw  } from 'vue'
import { mapGetters } from 'vuex';
import bus from '@/js/event-bus.js';
import { useVueFuse } from 'vue-fuse'
import { useStore } from 'vuex';

export default {
    name: 'Search',
    computed: {
        ...mapGetters([
            'categoryList'
        ])
    },
    data () {
        return {
            searchInput: null,
            reportList: null,
            reportNumberList: [],
            defaultAllToggle: false,
            minMatchCharLength: 3,
            highlightMinMatchLength: false,
            results: [],
            simpleHighlight: true,
            mobileVisible: false,
            isMobile: window.isMobile,
            noResults: [],
            filters: window.filters 
        };
    },
    setup () {
        const store = useStore();
        let rawData;

        if (isProxy(store.state.categoryList)){
            rawData = toRaw(store.state.categoryList)
        }
        
        const reportList = window.buildReportList(rawData);
        const { search, results, noResults } = useVueFuse(reportList, {
            keys: [
                { name: 'ReportId' },
                { name: 'ReportName' },
            ]
        });

        return {
            search,
            results,
            noResults,
        }
    },
    mounted () {
        this.reportList = window.buildReportList(this.categoryList);
        this.reportNumberList = window.getAllReportNumbers(this.categoryList);

        window.list = this.reportNumberList;

        $(window).resize(() => {
            this.mobileCheck();
        });

        this.$nextTick(() => {
            this.searchInput = $(this.$el).find('input');

            this.searchInput.attr('id', 'search-input');

            this.searchInput.click((event) => {
                event.stopPropagation();
            });

            this.searchInput.on('keydown', (event) => {
                switch (event.keyCode) {
                    case 40: // ArrowDown
                        event.preventDefault();
                        $('.search-results-list > ul > li:nth-child(2) .search-result-item').focus();
                        break;
                    case 27: // Escape
                        this.resetSearch();
                        break;
                    case 13: // Enter
                        $('.search-results-list > ul > li:first-child .search-result-item').click();
                        this.resetSearch();
                        break;
                }
            });

            $('body').click(() => {
                this.resetSearch();
            });
        });
    },
    methods: {
        toggle () {
            this.defaultAllToggle = !this.defaultAllToggle;
            this.$forceUpdate();
        },
        mobileCheck () {
            this.isMobile = window.isMobile;

            // if (!window.isMobile || (window.isMobile && this.mobileVisible)) {
            // } else {
            //     this.isMobile = false;
            // }
        },
        toggleMobileSearch () {
            this.mobileVisible = !this.mobileVisible;

            if (this.mobileVisible) {
                this.$nextTick(() => {
                    $('#search-input').focus();
                });
            }
            // if (this.isMobile) {
            //     if (this.mobileVisible) {
            //         this.goToReport();
            //     } else {
            //         this.mobileVisible = true;
            //     }
            // } else {
            //     this.goToReport();
            // }
        },
        resultItemKeydown (event) {
            switch (event.keyCode) {
                case 38: // ArrowUp
                    event.preventDefault();
                    let li = $(event.target).closest('li');

                    if (li.prev().length === 0 || li.prev().prev().length === 0) {
                        this.searchInput.focus();
                    } else {
                        li.prev().find('.search-result-item').focus();
                    }

                    break;
                case 40: // ArrowDown
                    event.preventDefault();
                    $(event.target).closest('li').next().find('.search-result-item').focus();
                    break;

                case 27: // Escape
                    $(event.target).blur();
                    this.resetSearch();
                    break;

                case 13: // Enter
                    this.$nextTick(() => {
                        $(event.target).blur();
                        this.resetSearch();
                    });
                    break;

                default:
                    this.searchInput.focus();
                    break;
            }
        },
        resetSearch () {
            this.search = '';
            this.results = [];
        },
        goToReport () {
            if (!isNaN(this.searchTerm)) {
                if (this.reportNumberList.indexOf(Number(this.searchTerm)) !== -1) {
                    this.loadReport(this.searchTerm);
                    this.resetSearch();
                }

                if (this.isMobile) {
                    this.mobileVisible = false;
                }
            }
        },
        loadReport: function (id) {
            // clear any query parameters that may be present when changing to a new report
            this.$router.replace({query: null}).catch(()=>{});
            this.search = '';

            this.$router.push({
                path: '/report/' + id
            }).catch(()=>{});

            if (this.isMobile) {
                this.mobileVisible = false;
            }

            if (!isNaN(this.searchTerm)) {
				gtag('event','Search', {
					'event_category': 'Search by ID',
					'event_label': 'Report ' + id,
					'value': 'Search by ID ' + id
				});	
            } else {
				gtag('event','Search', {
					'event_category': 'Search by Title',
					'event_label': this.searchTerm,
					'value': 'Search by ID ' + id
				});	
            }
        },
        highlightResults (results) {
            var highlightedResults = copyData(results),
                i,
                i2,
                i3,
                indices,
                highlightText,
                highlightStart = '<span class="search-highlight">',
                highlightEnd = '</span>',
                start,
                middle,
                end;

            // loop through search results list
            for (i = 0; i < highlightedResults.length; i++) {

                // set data to exact text first
                // incase there is no highlight
                highlightedResults[i].item.ReportNameHighlight = highlightedResults[i].item.ReportName.toString();

                highlightedResults[i].item.ReportIdHighlight = highlightedResults[i].item.ReportId.toString();

                // loop through matches
                for (i2 = 0; i2 < highlightedResults[i].matches.length; i2++) {
                    indices = highlightedResults[i].matches[i2].indices;
                    highlightText = highlightedResults[i].item[highlightedResults[i].matches[i2].key].toString();

                    // loop backwards over the indices so that adding
                    // the highlight does not throw off the earlier posistions
                    for (i3 = indices.length - 1; i3 > -1; i3--) {
                        // if ((this.highlightMinMatchLength && indices[i3][1] - indices[i3][0] * -1  >= this.minMatchCharLength) || indices[i3][0] === 0 || highlightText.charAt(indices[i3][0] - 1).match(/[\s]/g)) {
                        if ((this.highlightMinMatchLength && indices[i3][1] - indices[i3][0] * -1  >= this.minMatchCharLength) || !this.highlightMinMatchLength) {
                            start = highlightText.slice(0, indices[i3][0]);
                            middle = highlightText.slice(indices[i3][0], indices[i3][1] + 1);
                            end = highlightText.slice(indices[i3][1] + 1, highlightText.length);

                            highlightText = start + highlightStart + middle + highlightEnd + end;

                            highlightedResults[i].item[highlightedResults[i].matches[i2].key + 'Highlight'] = highlightText;
                        }
                    }
                }
            }

            return highlightedResults;
        }
    },
    created () {
        bus.$on('searchChanged', results => {
            if (this.simpleHighlight) {
                this.results = results;
            } else {
                this.results = this.highlightResults(results);
            }
        });

        bus.$on('searchInputChanged', value => {
            this.searchTerm = value;
        });
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #report-search {
        position: relative;
        float: right;
        .search-wrapper {
            @media only screen and (max-width: 768px) {
                display: block;
                position: fixed;
                margin: 0;
                padding: 7px 10px;
                width: 100vw;
                left: 0;
                top: 44px;
                background-color: #043e65;
                #search-input {
                    width: 100%;
                }
                .search-button {
                    top: 7px;
                    right: 10px;
                }
                .search-results-list {
                    width: 100%;
                }
            }
        }
        .search-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 6px;
            height: 31px;
            background: none;
            border: none;
            outline: none;
            pointer-events: none;
            z-index: 6;
            // &:hover,
            // &:focus {
            //     .search-icon {
            //         .color1 {
            //             fill: $blue;
            //         }
            //     }
            // }
            // @media only screen and (max-width: 768px) {
            //     pointer-events: auto;
            // }
            &.search-button-mobile {
                pointer-events: auto;
            }
        }
        .search-icon {
            width: 23px;
            height: 23px;
            .color1 {
                fill: $hover-category-header-background;
                transition: 0.3s;
            }
        }
    }
    #search-input {
        position: relative;
        width: 300px;
        border: none;
        border-radius: 3px;
        padding: 0.35em 0.5em;
        transition: 0.3s;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        outline: none;
        z-index: 5;

        &:focus {
            // width: 300px;
            ~ .search-button {
                pointer-events: all;
                .search-icon {
                    .color1 {
                        fill: darken($hover-category-header-background, 20%);
                    }
                }
                &:hover,
                &:focus {
                    .search-icon {
                        .color1 {
                            fill: $active-category-header-background;
                        }
                    }
                }
            }
            ~ .search-results-list:not(:hover) {
                ul {
                    li:first-child {
                        .search-result-item {
                            color: $blue-dark;
                            background-color: $hover-category-header-background;
                            background-color: lighten($hover-category-header-background, 5%);
                            .report-id {
                                color: $grey;
                            }
                        }
                    }
                }
            }
        }
    }
    .search-results-list {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: -3px;
        width: 300px;
        max-height: 50vh;
        min-height: 200px;
        color: #000;
        background-color: #fff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        overflow: auto;
        border-radius: 0 0 5px 5px;
        z-index: 1;
        transition: 0.3s;
        &.collapse {
            min-height: 0;
            height: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            &:first-child {
                .search-result-item {
                    padding-top: 10px;
                }
            }
        }
        .search-result-item {
            display: block;
            padding: 5px 10px;
            text-align: left;
            background: none;
            border: none;
            width: 100%;
            outline: none;
            &:hover,
            &:focus {
                text-decoration: none;
                color: $blue-dark;
                background-color: lighten($hover-category-header-background, 5%);
                .report-id {
                    color: $grey;
                }
            }
            .report-name {
                display: block;
                line-height: 1em;
                margin-bottom: 0.15em;
                margin-left: 0;
            }
            .report-id {
                display: block;
            }
        }
    }
</style>
