<template>
    <div class="report-data-wrapper" v-if="tableData.data && tableData.data[0]" ref="dataWrapper">
        <div class="report-data" :class="Array.isArray(tableData.data[0][0]) ? 'multiple' : ''" ref="reportDataContent">
            <div class="report-table-wrapper" v-if="!Array.isArray(tableData.data[0][0])">
                <table>
                    <thead>
                        <tr v-if="tableData.topTableHeaders" class="top-table-headers">
                            <th v-for="(header, index) in tableData.topTableHeaders"
                        :key="$parent.reportId + '-th-top-' + index" :colspan="header.colSpan">{{header.name}}</th>
                        </tr>
                        <tr>
                            <th v-for="(header, index) in tableData.data[0]"
                        :key="$parent.reportId + '-th-' + index">{{header}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in tableData.data.slice(1)"
                        :key="$parent.reportId + '-tr-' + index">
                            <td x:num  v-for="(col, index2) in row" :key="$parent.reportId + '-tr-' + index + '-td-' + index2"
                                :class="[tableData.rowHighlight ? 'highlight-' + tableData.rowHighlight[index] : '', tableData.data[0][index2] == 'Subgroup' || tableData.data[0][index2] == 'Subfield' ? 'fixed-column-Header' : '']"
                                >{{ filters.globalFormat(col, tableData.data[0][index2], (tableColumnFormat ? tableColumnFormat[index2] : null)) }}
                            </td>
                            <td class="d-none">{{row}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="report-table-wrapper" v-if="Array.isArray(tableData.data[0][0])" v-for="(table, index) in tableData.data" :key="$parent.reportId + '-table-' + index">
                <table>
                    <thead>
                        <tr>
                            <th v-for="(header, index2) in table[0]"
                        :key="$parent.reportId + '-th-' + index2" v-html="header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index2) in table.slice(1)"
                        :key="$parent.reportId + '-tr-' + index + '-' + index2">
                            <td x:num v-for="(col, index3) in row"
                        :key="$parent.reportId + '-tr-' + index + '-' + index2 + '-td-' + index3">
                            {{ filters.globalFormat(col, table[0][index3], (tableColumnFormat ? tableColumnFormat[index][index3] : null))}}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div :id="'report-' + this.$parent.reportId + '-data'" class="d-none">
                <table v-if="exportData.data" class="export-table">
                    <thead>
                        <tr v-if="exportData.data[0]">
                            <td v-bind:colspan="exportData.data[0].length > 15 ? exportData.data[0].length : 15">{{$parent.title}}</td>
                        </tr>
                        <tr v-if="exportData.data[0]">
                            <td v-bind:colspan="exportData.data[0].length > 15 ? exportData.data[0].length : 15"></td>
                        </tr>
                        <tr v-if="$parent.activeDataSet">
                            <td v-bind:colspan="exportData.data[0].length > 15 ? exportData.data[0].length : 15" v-if="$parent.activeDataSetSecondary">Data for {{$parent.activeDataSet}} - {{$parent.activeDataSetSecondary}}</td>
                            <td v-bind:colspan="exportData.data[0].length > 15 ? exportData.data[0].length : 15" v-else>Data for {{$parent.activeDataSet}}</td>
                        </tr>
                        <tr v-if="$parent.activeDataSet">
                            <td v-bind:colspan="exportData.data[0].length > 15 ? exportData.data[0].length : 15"></td>
                        </tr>
                        <tr v-if="tableData.topTableHeaders" class="top-table-headers">
                            <th v-for="(header, index) in tableData.topTableHeaders"
                        :key="$parent.reportId + '-th-top-' + index" :colspan="header.colSpan">{{header.name}}</th>
                        </tr>
                        <tr>
                            <th v-for="(header, index) in exportData.data[0]"
                        :key="$parent.reportId + '-th-' + index">{{header}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in exportData.data.slice(1)"
                        :key="$parent.reportId + '-tr-' + index">
                            <td x:num v-for="(col, index2) in row"
                                :key="$parent.reportId + '-tr-' + index + '-td-' + index2"
                            >
                                {{ filters.addSymbolInHeader(col, tableColumnFormat, index2) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div  v-show="isModalView && $parent.footnotes && $parent.footnotes.length !== 0">
            <footnotes ref="footnotes" v-if="!miniMode && !chartOnly" :reportFootNotes="$parent.footnotes" />
        </div>
        <div class="last-updated" v-if="$parent.lastUpdated && isModalView">Last Updated: {{$parent.lastUpdated}}</div>
    </div>
</template>
<script>
import VueMarkdown from 'vue3-markdown-it';
import footnotes from './footnotes.vue';

export default {
    name: 'dataTable',
    props: [
        // 'id',
        // 'chartOptions'
        'tableData',
        'exportData',
        'activeDataSet',
        'activeDataSetSecondary'
    ],
    components: {
        VueMarkdown,
        footnotes
    },
    mounted () {
        this.checkColumnFormat();
        this.isModalView = false;

        // make sure table is rendered or setting scroll position does not work
        this.$nextTick(() => {
            if (!Array.isArray(this.tableData.data[0][0])) {
                this.floatAllTableHeaders();
                this.scrollMostRecentYearsIntoView();
                this.resizeFloatedHeaders();
            }
        });

        var self = this;

        $('#report-' + this.$parent.reportId + '-modal').on('shown.bs.modal', function (e) {
            self.resizeFloatedHeaders();
            $(e.target).find('.floated-table-header').addClass("shown");
        });
    },
    data () {
        return {
            // eChart: null,
            // animate: true,
            // resizeTimeout: null,
            // exportChart: null
            tableColumnFormat: null,
            resizeFloatedHeadersLeft: [],
            isModalView: false,
            filters: window.filters
        };
    },
    watch: {
        activeDataSet: function () {
            this.checkColumnFormat();

            this.$nextTick(() => {
                var floatedTableWrapper = this.$refs.reportDataContent.getElementsByClassName('floated-table-header')[0];

                floatedTableWrapper.remove();
                this.floatAllTableHeaders();
                this.resizeFloatedHeaders();
            });
        },
        activeDataSetSecondary: function () {
            this.checkColumnFormat();
            this.$nextTick(() => {
                var floatedTableWrapper = this.$refs.reportDataContent.getElementsByClassName('floated-table-header')[0];

                floatedTableWrapper.remove();
                this.floatAllTableHeaders();
                this.resizeFloatedHeaders();
            });
        }
    },
    methods: {
        markdownCleanup (footnote) {
            return window.markdownCleanup(footnote);
        },

        markupCleanup (footnote) {
            return footnote.replace(/\*\s/, '*&nbsp;');
        },

        updateAfterModalOpen () {
            this.isModalView = true;
            this.resizeFloatedHeaders();
            this.scrollMostRecentYearsIntoView();
        },

        checkColumnFormat () {
            if (this.tableData.columnFormat && Array.isArray(this.tableData.columnFormat[0])) {
                if (this.$parent.data.dataSets) {
                    let activeDataSetIndex = this.$parent.data.dataSets.indexOf(this.$parent.activeDataSet);

                    if (this.$parent.data.dataSetsSecondary) {
                        let activeSecondaryDataSetIndex = this.$parent.data.dataSets.indexOf(this.$parent.activeDataSet);

                        this.tableColumnFormat = this.tableData.columnFormat[activeDataSetIndex][activeSecondaryDataSetIndex];
                    } else {
                        this.tableColumnFormat = this.tableData.columnFormat[activeDataSetIndex];
                    }
                } else {
                    // this.tableColumnFormat = this.tableData.columnFormat[0];
                    this.tableColumnFormat = this.tableData.columnFormat;
                }
            } else {
                this.tableColumnFormat = this.tableData.columnFormat;
            }
        },

        scrollMostRecentYearsIntoView () {
            var dataWrapper = this.$el,
                tableWrappers = dataWrapper.getElementsByClassName('report-table-wrapper'),
                tableWrapper,
                table,
                tableHeaders,
                lastTableHeader;

            for (let i = 0; i < tableWrappers.length; i++) {
                tableWrapper = tableWrappers[i];
                table = tableWrapper.getElementsByTagName('table')[0];
                tableHeaders = table.getElementsByTagName('th');
                lastTableHeader = tableHeaders[tableHeaders.length - 1];

                // only scroll for years
                if (!isNaN(lastTableHeader.innerText) && lastTableHeader.innerText.length === 4) {
                    // dataWrapper.scrollLeft = table.offsetWidth - dataWrapper.offsetWidth;
                    tableWrapper.scrollLeft = table.offsetWidth - tableWrapper.offsetWidth;
                }
            }
        },

        floatAllTableHeaders () {
            // this.floatTopTableHeaders();
            this.floatLeftTableHeaders();
        },

        resizeFloatedHeaders () {
            this.resizeFloatedLeftTableHeaders();
        },

        /* eslint-disable */
        resizeFloatedLeftTableHeaders () {
            let tableWrappers = this.$el.getElementsByClassName('report-table-wrapper'),
                tableWrapper,
                table,
                tableHeaderRows,
                tableHeaders,
                tableRows,
                floatedTableWrapper,
                floatedTable,
                floatedTableHeaderRows,
                floatedTableHeaders,
                floatedTableRows;

            for (let i = 0; i < tableWrappers.length; i++) {
                if (tableWrappers[i].getElementsByTagName('table').length > 0) {
                    tableWrapper = tableWrappers[i];
                    table = tableWrapper.getElementsByTagName('table');

                    if (table && table.length > 0) {
                        tableHeaderRows = table[0].getElementsByTagName('thead')[0].getElementsByTagName('tr');
                        tableHeaders = table[0].getElementsByTagName('th');
                        tableRows = table[0].getElementsByTagName('tr');

                        floatedTableWrapper = this.$refs.reportDataContent && this.$refs.reportDataContent.getElementsByClassName('floated-table-header-left');
                        if (floatedTableWrapper && floatedTableWrapper.length > 0) {
                            floatedTable = floatedTableWrapper[0].getElementsByTagName('table')[0];
                            floatedTableHeaderRows = floatedTable.getElementsByTagName('thead')[0].getElementsByTagName('tr');
                            floatedTableHeaders = floatedTable.getElementsByTagName('th');
                            floatedTableRows = floatedTable.getElementsByTagName('tr');

                            // for each floated header, set width and height to
                            // that of corresponding header from main table
                            for (let i2 = 0; i2 < floatedTableHeaderRows.length; i2++) {
                                let headers = tableHeaderRows[i2].getElementsByTagName('th'),
                                    floatedHeaders = floatedTableHeaderRows[i2].getElementsByTagName('th');

                                for (let i3 = 0; i3 < floatedHeaders.length; i3++) {
                                    floatedHeaders[i3].style.width = headers[i3].offsetWidth + 'px';
                                    // floatedHeaders[i3].style.height = headers[i3].offsetHeight + 'px';
                                    floatedHeaders[i3].style.height = headers[i3].getBoundingClientRect().height + 'px';
                                }
                            }

                            for (let i2 = 0; i2 < floatedTableRows.length; i2++) {
                                let tableData = tableRows[i2].getElementsByTagName('td'),
                                    floatedTableData = floatedTableRows[i2].getElementsByTagName('td');

                                for (let i3 = 0; i3 < floatedTableData.length; i3++) {
                                    floatedTableData[i3].style.width = tableData[i3].offsetWidth + 'px';
                                    floatedTableData[i3].style.height = tableData[i3].getBoundingClientRect().height + 'px';
                                    floatedTableData[i3].style.minHeight = floatedTableData[i3].style.height;
                                }
                            }
                        }
                    }
                }
            }
        },

        floatLeftTableHeaders () {
            // var tables = this.$el.getElementsByTagName('table'),
            var tableWrappers = this.$el.getElementsByClassName('report-table-wrapper'),
                firstTd,

                floatedHeader,
                floatedHeaderTable,
                floatedHeaderTheadRows,
                floatedHeaderTableRow,
                columns = 1,

                floatedHeaders = [],
                resize;


            for (let i = 0; i < tableWrappers.length; i++) {
                let tableWrapper = tableWrappers[i],
                    table = tableWrapper.getElementsByTagName('table')[0],
                    tableHeaders = table.getElementsByTagName('th'),
                    thead = table.getElementsByTagName('thead')[0],
                    theadRows = thead.getElementsByTagName('tr'),
                    tableRows = table.getElementsByTagName('tr'),

                    floatedThead = document.createElement('thead'),

                    tableRowHeaders = [];
                    // floatedTableHeaders = [];

                // create floated header element
                floatedHeader = document.createElement('div');
                floatedHeader.classList.add('floated-table-header');
                floatedHeader.classList.add('floated-table-header-left');
                floatedHeaderTable = document.createElement('table');

                floatedHeader.appendChild(floatedHeaderTable);

                for (let i2 = 0; i2 < theadRows.length; i2++) {
                    let headers = theadRows[i2].getElementsByTagName('th'),
                        firstHeader = headers[0],
                        secondHeader = headers[1];

                    // floatedTableHeaders.push(firstHeader.cloneNode(true));

                    floatedHeaderTableRow = document.createElement('tr');
                    floatedHeaderTableRow.appendChild(firstHeader.cloneNode(true));

                    if (secondHeader.innerText.toLowerCase() === 'subgroup' || secondHeader.innerText.toLowerCase().indexOf('subfield') !== -1) {
                        columns++;
                        // floatedTableHeaders.push(secondHeader.cloneNode(true));
                        // floatedHeaderTableRow.appendChild(floatedTableHeaders[1]);
                        floatedHeaderTableRow.appendChild(secondHeader.cloneNode(true));
                    }

                    floatedThead.appendChild(floatedHeaderTableRow);
                }

                floatedHeaderTable.appendChild(floatedThead);

                // if (tableHeaders[0]) {
                //     floatedTableHeaders.push(tableHeaders[0].cloneNode(true));

                //     floatedHeaderTableRow = document.createElement('tr');
                //     floatedHeaderTableRow.appendChild(floatedTableHeaders[0]);

                //     if (tableHeaders[1].innerText.toLowerCase() === 'subgroup') {
                //         floatedTableHeaders.push(tableHeaders[1].cloneNode(true));
                //         floatedHeaderTableRow.appendChild(floatedTableHeaders[1]);
                //     }

                //     floatedHeaderTable.appendChild(floatedHeaderTableRow);
                // }

                // add all headers from tbody rows
                for (let i2 = 0; i2 < tableRows.length; i2++) {
                    let tableData = tableRows[i2].getElementsByTagName('td');

                    // get only first td
                    // update to fix 2 headers in future conditionally
                    if (tableData && tableData.length !== 0) {
                        floatedHeaderTableRow = document.createElement('tr');

                        // console.log(floatedTableHeaders);
                        // console.log(theadRows[0].getElementsByTagName('th').length);

                        // for (let i3 = 0; i3 < floatedTableHeaders.length; i3++) {
                        for (let i3 = 0; i3 < columns; i3++) {
                            floatedHeaderTableRow.appendChild(tableData[i3].cloneNode(true));
                        }

                        floatedHeaderTable.appendChild(floatedHeaderTableRow);
                    }
                }

                // add this floated header to array of all floated headers
                floatedHeaders.push(floatedHeader);
                // add this floated ehader to DOM
                // tableWrapper.appendChild(floatedHeader);
                this.$refs.reportDataContent.appendChild(floatedHeader);
            }

            // update position of fixed headers on scroll
            // this.$refs.dataWrapper.addEventListener('scroll', (event) => {
            //     let scrollLeft = this.$refs.dataWrapper.scrollLeft;

            //     // check all fixed headers on page
            //     for (let i = 0; i < floatedHeaders.length; i++) {
            //         // check existing style.left against scrollLeft to prevent
            //         // updating variable to same value and repainting DOM needlessly
            //         if (floatedHeaders[i].style.left !== scrollLeft + 'px') {
            //             floatedHeaders[i].style.left = scrollLeft + 'px';
            //         }
            //     }
            // });

            window.addEventListener('resize', (event) => {
                this.resizeFloatedLeftTableHeaders();
            });

        },       
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '@/scss/variables.scss';

    table {
        width: 100%;
        // margin: auto;
        // min-width: 50%;
        font-size: 14px;

        th,
        td {
            padding: 2px 10px;
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }

        th {
            color: #fff;
            background: #555;
            line-height: 1.2em;
            &:first-child {
                white-space: pre;
            }
        }

        td {
            font-size: 14px;
            border-bottom: 1px solid #ddd;
            vertical-align: middle;

            &:first-child {
                font-weight: 500;
                background-color: #f7f7f7;
            }
            &.fixed-column-Header {
                background-color: #f7f7f7;
            }
        }

        .top-table-headers {
            th {
                text-align: center;
                background-color: #717171;
                border-left: 2px solid #555;

                &:first-child {
                    border: none;
                }
            }
        }
    }

    .report-table-wrapper {
        position: relative;
        overflow: auto;
        width: 100%;
    }

    .floated-table-header {
        position: absolute;
        top: 0;
        left: 0;

        th,
        td {
            position: relative;
            background: none;
            z-index: 1;
            text-align: left;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                width: 15px;
                height: 100%;
                z-index: -1;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ccc;
                z-index: -1;
            }
        }

        th {
            background-color: #757575;
            &:before {
                background: linear-gradient(to right, rgba(85,85,85,1) 0%,rgba(85,85,85,0) 100%);
            }
            &:after {
                background-color: #757575;
            }
        }

        td {
            background-color: #f7f7f7;
            &:before {
                background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            }
            &:after {
                background-color: #f7f7f7;
            }
            // &.highlight-1,
            // &.highlight-2,
            // &.highlight-3 {
            //     ~ td {
            //         padding-left: 6em;
            //     }
            // }
        }
    }

    table,
    .floated-table-header {
        td {
            &.highlight-strong,
            &.highlight-1,
            &.highlight-2,
            &.highlight-3 {
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-strong {
                color: #fff;
                &,
                &:after {
                    background-color: $blue;
                }
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-1 {
                &,
                &:after {
                    background-color: #b1d1f1;
                }
                &:before {
                    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
                }
            }
            &.highlight-2 {
                padding-left: 1.5em;
                &,
                &:after {
                    background-color: #d3e6f9;
                }
            }
            &.highlight-3 {
                padding-left: 3em;
                &,
                &:after {
                    background-color: #edf6ff;
                }
            }
            &.highlight-3-end {
                padding-left: 3em;
            }
            &.highlight-4 {
                padding-left: 4.5em;
            }
        }
    }

    #report-modal {
        .report-footnotes-wrapper {
            max-height: 100%;
            padding-bottom: 0;
            position: initial;
            color: #212529;
             &:before {
                 display: none;
             }
        }

        .last-updated {
            position: relative;
            left: 0;
            bottom: 0;
            padding-top: 10px;
        }
    }

    .modal.fade {
        .floated-table-header {
            opacity: 0;
            &.shown {
                opacity: 1;
            }
        }
    }
    // .level2 {
    //     font-weight: 700;
    //     // color: $green;
    //     // background-color: #eee;
    // }
    // .level3 {
    //     font-weight: 500;
    //     // color: $green;
    // }

</style>
