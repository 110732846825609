import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/components/Main.vue';
import ReportEmbed from '@/components/ReportEmbed.vue'; // eslint-disable-line

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main
        },
        {
            path: '/index.aspx',
            name: 'MainASPX',
            component: Main
        },
        {
            path: '/report/:id',
            component: Main
        },
        {
            path: '/embedReport/:id',
            component: ReportEmbed
        },
        {
            path: '/category/:categoryId',
            component: Main
        },
        {
            path: '/:subsite',
            component: Main
        },
        {
            path: '/:subsite/',
            component: Main
        },
        {
            path: '/:subsite/report/:id',
            component: Main
        },
        {
            path: '/:subsite/embedReport/:id',
            component: ReportEmbed
        },
        {
            path: '/:subsite/page/:page',
            component: Main
        },
        {
            path: '/page/:page',
            component: Main
        },
        {
            path: '/:subsite/page/:page/:pageId',
            component: Main
        },
        {
            path: '/page/:page/:pageId',
            component: Main
        },
        {
            path: '/Charts/:oldAspxPage',
            component: Main
        },
        {
            path: '/:subsite/Charts/:oldAspxPage',
            component: Main
        }
    ]
});

export default router;
