(function () {
    'use strict';

    function viewport () {
        var e = window,
            a = 'inner';

        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }

        return {
            width: e[a + 'Width'],
            height: e[a + 'Height']
        };
    }

    function mobileCheck () {
        var largeScreenWidth = !!document.getElementById('single-report-view') ? 700 : 1490;

        // if (viewport().width < 768) {
        if (viewport().width < 992) {
            window.isMobile = true;
        } else {
            window.isMobile = false;
        }

        if (viewport().width >= largeScreenWidth) {
            window.isLargeScreen = true;
        } else {
            window.isLargeScreen = false;
        }
    }

    window.mobileCheck = mobileCheck;
}());
