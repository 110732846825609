window.sharedSpecs = {
    reports: {
        title: null,
        init: false,
        hasChart: false,
        chartOptions: null,
        noData: false,
        chartAxesLabels: {
            x: null,
            y: null,
            y2: null
        },
        // legend: [],
        legend: {
            y: [],
            y2: [],
            all: []
        },
        unSelectedLegends: [],
        footnotes: []
    },
    services: {
        export: null
    },
    eCharts: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        color: [
            '#084081',
            '#2283ba',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#2283ba',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#2283ba',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',

            '#084081',
            '#2283ba',
            '#67c1cb',
            '#b4e2ba',
            '#faecaf',
            '#f7bb6c',
            '#f78c55',
            '#C54A53',
        ],
        legend: {
            // type: 'scroll',
            align: 'right',
            right: 6,
            itemWidth: 14,
            itemHeight: 14
        },
        tooltip: {
            trigger: 'item',
            confine: true,
            axisPointer: {
                shadowStyle: {
                    color: 'rgba(8, 130, 220, 0.1)'
                },
                type: 'shadow',
                z: '0',
                axis: 'x'
            }
        },
        grid: {
            top: '10',
            left: '20',
            right: '20',
            bottom: '20',
            containLabel: true
        },
        symbol: 'circle',
        symbolSize: 8,
        normal: {
            itemStyle: {
                borderWidth: 2
            },
            lineStyle: {
                // color: colors[i],
                // shadowColor: 'rgba(0, 0, 0, 0.15)',
                // shadowBlur: 5,
                width: 3
            }
        }
    }
};