<template>
    <div class="mini-report-view">
    <!-- <div id="mini-report-view" @click.prevent="expandReport()"> -->
        <report :reportId="parseInt(reportId)" :miniMode="true" ref="report" :LRPReport="LRPReport" :isExtramuralCategory="isExtramuralCategory"></report>
    </div>
</template>

<script>
import Report from '@/components/Report';
export default {
    name: 'ReportMini',
    props: [
        'reportId',
        'LRPReport',
        'isExtramuralCategory'

    ],
    components: {
        Report
    },
    data () {
        return {
            chartTargetHeight: 250
        };
    },
    mounted () {
        window.addEventListener('resize', () => {
            this.sizeChart();
        });
    },
    methods: {
        expandReport () {
            this.$refs.report.expandReport();
        },
        sizeChart () {
            var report,
                chart,
                chartEl,
                heightDifference;

            if (this.$refs.report && this.$refs.report.$refs.chart) {
                report = this.$refs.report.$el;

                chart = this.$refs.report.$refs.chart;
                chartEl = chart.$el.getElementsByClassName('chart')[0];

                heightDifference = report.clientHeight - chartEl.clientHeight;

                chartEl.style.minHeight = (this.chartTargetHeight - heightDifference) + 'px';

                chart.resizeChart();
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
