export default window.filters = {

getFirstPIName (nameList) {
    if (nameList !== undefined && nameList !== null) {
        nameList = nameList.split(';');
        if (nameList.length > 1) {
            if ($.trim(nameList[0]) === ',') {
                return '';
            }

            return $.trim(nameList[0]);
        } else {
            if ($.trim(nameList[0]) === ',') {
                return '';
            }

            return $.trim(nameList[0]);
        }
    }
},
removeFirstPIName (nameList) {
    if (nameList !== undefined && nameList !== null) {
        nameList = nameList.split(';');
        var uniqueNames = [];

        $.each(nameList, function (i, el) {
            if ($.inArray(el, uniqueNames) === -1) {
                uniqueNames.push(el);
            }
        });

        if (uniqueNames.length > 1) {
            uniqueNames.shift();
            uniqueNames = '; ' + uniqueNames.join('; ');
            return uniqueNames;
        }
    }
},
removeFirstNameWithEmail (nameList, email) {
    if (nameList !== undefined && nameList !== null) {
        nameList = nameList.split(';');
        var uniqueNames = [];

        $.each(nameList, function (i, el) {
            if ($.inArray(el, uniqueNames) === -1) {
                uniqueNames.push(el);
            }
        });

        if (uniqueNames.length > 1) {
            uniqueNames.shift();
            uniqueNames = '; ' + uniqueNames.join('; ');
            return uniqueNames + '<br><br>Contact: ' + email;
        } else {
            return '<br><br>Contact: ' + email;
        }
    }
},
splitArrayWithParam (array, param) {
    if (array && param) {
        if ($.isNumeric(array)) {
            array = array.toString();
        }

        return array.split(param);
    }
},
formatDate (value) {
    var date,
        month,
        day,
        year;

    if (value) {
        date = new Date(value);

        month = (date.getMonth() + 1);
        day = date.getDate();
        year = date.getFullYear();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return month + '/' + day + '/' + year;
    }
},
formatDateTime (value) {
    var date,
        month,
        day,
        year,
        hours,
        minutes,
        period;

    if (value) {
        date = new Date(value);

        month = (date.getMonth() + 1);
        day = date.getDate();
        year = date.getFullYear();
        hours = date.getHours();
        minutes = date.getMinutes();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (hours > 12) {
            hours = hours - 12;
            period = 'PM';
        } else {
            period = 'AM';
        }

        return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;

    }
},
formatPubDate (date) {
    if (date) {
        return date.split(' ')[0];
    }
},
capitalizeFirst (value) {
    if (value) {
        value = value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
    }

    return value;
},
pluralize (value) {
    if (value !== 'Displayed') {
        if (value.charAt(value.length - 1) === 'y') {
            return value.substring(0, value.length - 1) + 'ies';
        } else {
            return value + 's';
        }
    }

    return value;
},
addCommas (value) {
    if(value == null) {
        return value;
    }
	if (!isNaN(Number(value))) {
		value = Number(value)
	}     
    if (typeof value === 'number') {
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    } else {
        return value;
    }
},
addBreakAfterCommas (value) {
    return value.split(',').join(', ');
},
breakAtUnderscore (value) {
    return value.split('_').join('_\u200B');
},
breakEmail (value) {
    return value.split('@').join('@\u200B').split('.').join('.\u200B');
},
formatCurrency (value) {
    var addCommas = window.filters.addCommas;

    if (!isNaN(value)) {
        return '$' + addCommas(value);
    } else {
        return value;
    }
},
roundAndFormatCurrency (value) {
    if (value === "") {
        return value;
    } else if (value >= 1000000000) {
        return '$' + (value / 1000000000).toFixed(0) + 'T';
    }
    else if (value >= 1000000) {
        return '$' + (value / 1000000).toFixed(0) + 'M';
    }
    else if (value > 999) {
        return '$' + (value / 1000).toFixed(0) + 'K';
    } else {
        return '$' + addCommas(value);
    }
},
formatLargeNumber (value) {
    var addCommas = window.filters.addCommas,
        round = {
            T: 0.000000000001,
            B: 0.000000001,
            M: 0.000001,
            K: 0.001
        },
        roundKey;

    if (value) {
        if (value.toString().length >= 4) {
            if (value.toString().length >= 13) {
                //if trillions
                roundKey = 'T';
            } else if (value.toString().length >= 10) {
                //if billions
                roundKey = 'B';
            } else if (value.toString().length >= 7) {
                //if millions
                roundKey = 'M';
            } else if (value.toString().length >= 4) {
                //if thousands
                roundKey = 'K';
            } else {
                value = addCommas(value);
            }

            if (roundKey) {
                value = value * round[roundKey];
                value = Math.round(value * 10) / 10;
                value = value + roundKey;
            }
        } else {
            value = addCommas(value);
        }
    } else {
        value = addCommas(value);
    }

    return value;
},
formatLargeCurrency (value) {
    var formatLargeNumber = window.filters.formatLargeNumber;

    return '$' + formatLargeNumber(value);
},
formatPercentage (value, formatD) {
    if (value === null) {
        return '*'
    } else if (isNaN(value)) {
        return value; 
    } else  if (formatD.split('.').length > 1) {
        value = Number.parseFloat(value).toFixed(formatD.split('.')[1].length);
    }
    return value + '%';
},
titleCase (title) {
    return title.toTitleCase();
},
appendNumberString (text) {
    return text + ", n";
},
globalFormat (value, colHeader, format) {
    if (format) {
        if (format.toString() === '$') {

            if (value === null) {
                return 0;
            }
            if (value === "") {
                return value;
            }

            return window.filters.formatCurrency(value);
        }
        if (format.toString().indexOf('%') !== -1) {
            if (value !== null && value !== "") {
                return window.filters.formatPercentage(value, format);
            }
        }
        else if (format.toString().indexOf('year') !== -1 || format.toString().indexOf('fy') !== -1) {
            return value;
        }

        if (format.toString().indexOf('disclosure') !== -1) {
            if (value !== null) {
                return window.filters.addCommas(value);;
            }

            return 'D';
        }


        return value;
    }

    colHeader = colHeader ? colHeader.toString().toLowerCase() : null;

    if (colHeader !== null && colHeader !== undefined && colHeader !== '') {
        if (colHeader.indexOf('year') !== -1 || colHeader.indexOf('fy') !== -1) {
            return value;
        }

        if (colHeader.indexOf('%') !== -1) {
            return window.filters.formatPercentage(value, colHeader);
        }

        if (!isNaN(parseInt(value)) || typeof value === 'number') {
            return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        } else {
            return value;
        }
    }

    return value;
},
addSymbolInHeader (value, colHeader, index) {
    if (colHeader && colHeader[index] !== null && colHeader[index] !== undefined && value && value !== "") {
        if (colHeader[index].toString().indexOf('%') !== -1) {
            if (colHeader[index].toString().split('.').length > 1) {
                value = Number.parseFloat(value).toFixed(colHeader[index].toString().split('.')[1].length);
            }
            return value + '%';
        }
    }

    return value;
},
chartAxisFormat (value, colHeader, format) {
    if (format) {
        if (format.toString() === '$') {

            return window.filters.formatCurrency(value);
        }
        else if (format.toString().indexOf('%') !== -1) {

            return window.filters.formatPercentage(value, format);
        }
        else if (format.toString().indexOf('year') !== -1 || format.toString().indexOf('fy') !== -1) {
            return value;
        }

    }

    if (colHeader) {
        colHeader = colHeader.toString().toLowerCase();

        if (colHeader.indexOf('year') !== -1 || colHeader.indexOf('fy') !== -1) {
            return value;
        }

        if (colHeader.indexOf('%') !== -1) {
            return window.filters.formatPercentage(value, colHeader);
        }
    }

    if (!isNaN(parseInt(value)) || typeof value === 'number') {
        return window.filters.formatLargeNumber(value);
    } else {
        return value;
    }
},
globalChartTooltipFormat (value, colHeader, format) {
    if (value !== null && value !== undefined && value !== '') {
        if (format) {
            if (format.toString() === '$') {

                return window.filters.formatCurrency(value);
            }
            else if (format.toString().indexOf('%') !== -1) {

                return window.filters.formatPercentage(value, format);
            }
            else if (format.toString().indexOf('year') !== -1 || format.toString().indexOf('fy') !== -1) {
                return value;
            }

        }

        if (colHeader) {
            colHeader = colHeader.toString().toLowerCase();

            if (colHeader.indexOf('year') !== -1 || colHeader.indexOf('fy') !== -1) {
                return value;
            }

            if (colHeader.indexOf('%') !== -1) {
                return window.filters.formatPercentage(value, colHeader);
            }
        }

        if (!isNaN(parseInt(value)) || typeof value === 'number') {
            return window.filters.addCommas(value);
        } else {
            return value;
        }
    }

    // return value;
    return 'N/A';
},
highlightTerms (value, terms) {
    var termsArray,
        termIndex,
        lowerCaseValue,
        searchMask,
        regEx,
        replaceMask,
        i;

    if (value !== null && value !== undefined && value !== '') {
        value = value.toString();
        termsArray = terms.toLowerCase().split(' ');
        lowerCaseValue = value.toLowerCase();

        for (i = 0; i < termsArray.length; i++) {
            termIndex = lowerCaseValue.indexOf(termsArray[i]);

            if (termIndex !== -1) {
                searchMask = termsArray[i];
                regEx = new RegExp(searchMask + '(?!([^<]+)?>)', 'ig');
                replaceMask = '<span class="search-highlight">' + value.slice(termIndex, termIndex + termsArray[i].length) + '</span>';

                value = value.replace(regEx, replaceMask);
                lowerCaseValue = value.toLowerCase();
            }
        }
    }

    return value;
}
}