<template>
    <div id="faq-view" class="page">
        <h1>NIH Data Book FAQs</h1>

        <div class="collapse-group" v-for="(faq, index) in faqs"
                        :key="'faq-' + index">
            <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + (index + 1)" aria-expanded="false" :aria-controls="'collapse-' + (index + 1)">
                <i class="fas fa-chevron-right collapse-icon"></i>
                <div class="btn-collapse-text ps-2">
                    {{faq.question}}
                </div>
            </button>
            <div class="collapse" :id="'collapse-' + (index + 1)">
                <div class="card card-body">
                    <vue-markdown :source="faq.answer" :postrender="markdownCleanup" class="mark-body"></vue-markdown>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueMarkdown from 'vue3-markdown-it';
export default {
    name: 'faq',
    components: {
        VueMarkdown
    },
    mounted () {
        let comp = this;

        document.title = this.$parent.pageTitle + ' - ' + this.pageTitle;

        if (this.$route.params.pageId) {
           $("[data-bs-target='#collapse-" + this.$route.params.pageId + "']").click(); 
           setTimeout(() => {
            $("a", $("[data-bs-target='#collapse-" + this.$route.params.pageId + "']").parent()).addClass('text-decoration-none')
           });
        }

        $('.btn-collapse').click(function () {
            var id = $(this).attr('data-bs-target').split('-')[1];

            // document.title = this.$parent.pageTitle;
            document.title = comp.$parent.pageTitle + ' - ' + comp.pageTitle + ': ' + id;
            comp.$router.push({
                path: '/page/faq/' + id
            }).catch(()=>{});
            $("a", $(this).parent()).addClass('text-decoration-none')
        });

    },
    data () {
        return {
            pageTitle: 'FAQ',
            faqs: [
                {
                    question: 'What information can I find in the NIH Data Book?',
                    answer: 'The NIH Data Book (NDB) provides answers to the most frequently asked questions about NIH research funding, including summary statistics on extramural grants and contract awards, grant applications, the organizations that NIH supports, the trainees and fellows supported through NIH programs, and the national biomedical workforce.\n\n' +
                    'Display tables and charts are provided in Excel and PNG formats; which can be opened in many common spreadsheet and presentation applications.\n\n' +
                    'Past Fiscal Year Data Books are provided in Microsoft PowerPoint PPT and PDF formats.'
                },
                {
                    question: 'Who provides the data for these reports?',
                    answer: 'Contributing sources can be found in the footnotes for each report.\n' +
                    'The primary contributors of data and analyses are:\n' +
                    '* NIH Statistical Analysis and Reporting Branch\n' +
                    '* NIH Division of Biomedical Research Workforce\n'+
                    '* NIH Office of Budget\n' +
                    '* National Science Foundation'
                },
                {
                    question: 'How often are the data in each report updated?',
                    answer: 'Generally, reports are updated once per fiscal year by group/category; for example, success rates are generally updated as a group, workforce in another group, and so forth. Each grouping typically updates on a unique time table.'
                    // The date of last update is listed on each slide.
                },
                {
                    question: 'I cannot locate a report; how do I find the information now?',
                    answer: 'All information from the prior list of reports has been incorporated into the enhanced Data Book interface. Some reports have been combined, such as multiple reports separated only by fiscal year groupings. In these cases, you can use the new filters provided on the report to view the subset of the data that previously appeared in a separate report.\n\n' +
                    'If you have any questions or comments on the Data Book, please address them to <RePORT@mail.nih.gov>'
                },
                {
                    question: 'What is the difference between the subcategories under the Research Grants category?',
                    answer: '**R01-Equivalents**\n' +
                        'NIH usually looks at R01s in conjunction with other awards providing similar support analogous to an R01, such as R37s or MERIT program awards. Of the R01-equivalent pool however, R01s make up the overwhelming bulk of these grants.\n' +
                        '<https://nexus.od.nih.gov/all/2015/04/10/looking-at-recent-data-on-r21-and-r01-equivalent-grants/>\n\n' +
                        '**Research Project Grants**\n' +
                        'An award made to an institution/organization to support discrete, specified, circumscribed projects to be performed by named investigators in areas representing their specific interest and competencies. NIH\'s most commonly used grant program is the Research Project Grant Program (R01). See Types of Grant Programs.\n\n' +
                        '**Research Center Grants**\n' +
                        'Center grants are large, multi-project efforts that generally include a diverse array of research activities. They are awarded to institutions on behalf of program directors and groups of collaborating investigators, and support long-term, multi-disciplinary programs of research and development.\n\n' +
                        '**Research Grants**\n' +
                        'Extramural awards made for other types of research grants, or combined information including data for multiple types of research grant programs.'
                }
            ]
        };
    },
    methods: {
        markdownCleanup (footnote) {
            return window.markdownCleanup(footnote);
        }
    }
    // $route.params.pageId
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mark-body {
    > p {
        background-color: rgb(243, 213, 243);
    }
    a {
        text-decoration: none !important;
    }
}
.download-list {
    list-style: none;
    margin: 30px 0;
    padding: 0;

    .heading {
        font-size: 16px;
        color: #333;
    }

    > li {
        margin-bottom: 20px;
    }
}

.btn-download {
    display: inline-block;
    padding: 6px 10px;
    background-color: #fff;
    color: #ad1700;
    // border: 1px solid #ab1800;
    border-radius: 4px;
    transition: 0.3s;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    img {
        margin-right: 5px;
    }
}
</style>
