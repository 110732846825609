(function () {
    window.getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'macos';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'windows';
        } else if (/Android/.test(userAgent)) {
            os = 'android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'linux';
        }

        return os;
    };

    /* IE CHECK */
    var isIE = false,
        ieVer,
        isAtLeastIE11;

    // Returns the version of Internet Explorer or a -1
    // (indicating the use of another browser).
    function getInternetExplorerVersion () {
        var rv = -1, // Return value assumes failure.
            ua,
            re;

        if (navigator.appName === 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }

        return rv;
    }

    ieVer = getInternetExplorerVersion();
    isAtLeastIE11 = !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));

    if (ieVer > 0 || isAtLeastIE11) {
        isIE = true;
    }

    window.isIE = isIE;
    window.ieVer = ieVer;
    window.isAtLeastIE11 = isAtLeastIE11;

    if (ieVer === 9) {
        if (!(window.console && console.log)) {
            console = { // eslint-disable-line
                log: function () {},
                debug: function () {},
                info: function () {},
                warn: function () {},
                error: function () {}
            };
        }
    }

    /* func: debounce
    TODO: desciption
    */
    window.debounce = (func, wait, immediate) => {
        var timeout;

        return function () {
            var context = this,
                args = arguments,
                later = function () {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                },

                callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };
})();
