<template>
    <div id="report-not-found-view" class="page">
        <h1>Report Not Found</h1>
        <p>We're sorry, the report you are looking for does not exist.</p>
    </div>
</template>

<script>
export default {
    name: 'ReportNotFound'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
