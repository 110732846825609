import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

// import { setTimeout } from 'timers';
import { log } from 'util'; // eslint-disable-line

(function () {
    var isIE = false,
        ieVer,
        isAtLeastIE11;

    function browserName () {
        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined',
            // Safari 3.0+ "[object HTMLElementConstructor]"
            isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window.safari || window.safari.pushNotification),
            // Internet Explorer 6-11
            isIE = /*@cc_on!@*/false || !!document.documentMode,
            // Edge 20+
            isEdge = !isIE && !!window.StyleMedia,
            // Chrome 1+
            isChrome = !!window.chrome && !!window.chrome.webstore;

        if (isFirefox) {
            return 'Firefox';
        }

        if (isSafari) {
            return 'Safari';
        }

        if (isIE) {
            return 'IE';
        }

        if (isEdge) {
            return 'Edge';
        }

        if (isChrome) {
            return 'Chrome';
        }
    }

    window.browserName = browserName;

    /**
     * Handles/tracks ajax calls
     */
    $(document).ajaxError((event, jqxhr, settings, thrownError) => {
        // console.info('event', event);
        // console.info('jqxhr', jqxhr);
        // console.info('setttings', settings);
        console.error('thrownError', thrownError);
    });

    $.ajaxSetup({
        // dataType: 'json',
        global: false,
        contentType: 'application/json',
    });

    window.customAjax = (options) => {
        var trackingURL = sanitizeUrl(options.service.url).split('?')[0],
            postData = options.data;

        if (options.service.isJSONData === undefined || options.service.isJSONData) {
            postData = JSON.stringify(options.data);
        }
        
        return $.ajax({
            type: options.service.type,
            url: sanitizeUrl(options.service.url),
            data: postData,
            xhrFields: options.service.xhrFields,
            crossDomain: options.service.crossDomain,
            contentType: options.service.contentType,
            success: (data) => {
				 gtag('event', 'timing_complete', {
					'name' : trackingURL,
					'value' :  new Date().getTime() - sanitizeUrl(options.service.url),
					'event_category' : 'Service Test - ' + window.browserName()
				  });
				  
                if (options.callback) {
                    options.callback(data, options.service);
                }
            },
            error: (xhr, status) => {
                if (status !== 'abort') {
                    if (options.callback) {
                        options.callback(null, options.service, xhr);
                    }
					
					 gtag('event', 'exception', {
						'description': options.service.url,
						'fatal': false
					  });
                }
            }
        });
    };

    // Create Element.remove() function if not exist (for IE 11)
    if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function () {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        };
    }

    /**
     * remove duplicate ? in urls
     */
    window.sanitizeUrl = (url) => {
        var pos = 0;

        if (url.indexOf('?') === -1) {
            if (url.indexOf('&') !== -1) {
                console.warn('%cUrl: ' + url, 'color: green');
            }

            url = url.replace(/\&/g, (m) => {
                pos += 1;
                return (pos === 1) ? '?' : m;
            });
        } else {
            url = url.replace(/\?/g, (m) => {
                pos += 1;
                return (pos !== 1) ? '&' : m;
            });

            url = url.replace(/\?&|\/\?/g, '?').replace(/&&/g, '&');
        }

        if (url.slice(-1) === '&' || url.slice(-1) === '?') {
            console.error('%cInvalid url: ' + url, 'color: red');
            url = url.slice(0, -1);
        }

        return url;
    };

    window.markdownCleanup = (footnote) => {
        //detect internal links
        if (footnote.indexOf('<a href="/') !== -1) {
            footnote = footnote.replace('<a href="/', '<a href="' + window.baseURL + '/');
        } else if (footnote.indexOf('<a href=/') !== -1) {
            footnote = footnote.replace('<a href=/', '<a href=' + window.baseURL + '/');
        } else if (footnote.indexOf('<a href=') !== -1 && footnote.indexOf('href="mailto:') === -1) {
            // force markdown links to open in new window
            // unless it is an email link
            return footnote.replace('<a href=', '<a target="_blank" href=');
        }

        return footnote;
    };

    /** get component of DOM
     * TODO: descriptiton
     */
    window.getVueComp = (dom) => {
        dom = dom[0];
        var key,
            comp;

        for (key in dom) {
            if (key.indexOf('__vue') !== -1) {
                comp = dom[key];

                return comp;
            }
        }

        return null;
    };

    /**
     * copyData
     * @param {*} data
     */
    window.copyData = (data) => {
        return data ? JSON.parse(JSON.stringify(data)) : data;
    };

    // allow users to enter numbers only
    window.allowNumbersInputOnly = function (options) {
        var field = $('#' + options.id),
            value = field.val(),
            invalidChars = /[^0-9]/gi;

        if (invalidChars.test(value)) {
            return value.replace(invalidChars, '');
        } else {
            return value;
        }
    };

    // allow both characters and numbers
    window.allowCharactersNumbersInputOnly = function (options) {
        var field = $('#' + options.id),
            value = field.val(),
            invalidChars = /[^a-zA-Z0-9]/gi;

        if (invalidChars.test(value)) {
            return value.replace(invalidChars, '');
            // field.val(value.replace(invalidChars, ''));
        } else {
            return value;
        }
    };

    // allow characters only
    window.allowCharactersInputOnly = function (options) {
        var field = $('#' + options.id),
            value = field.val(),
            invalidChars = /[^a-zA-Z]/gi;

        if (invalidChars.test(value)) {
            return value.replace(invalidChars, '');
            // field.val(value.replace(invalidChars, ''));
        } else {
            return value;
        }
    };

    // Returns the version of Internet Explorer or a -1
    // (indicating the use of another browser).
    function getInternetExplorerVersion () {
        var rv = -1, // Return value assumes failure.
            ua,
            re;

        if (navigator.appName === 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }

        return rv;
    }

    ieVer = getInternetExplorerVersion();
    isAtLeastIE11 = !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));

    if (ieVer > 0 || isAtLeastIE11) {
        isIE = true;
    }

    window.isIE = isIE;
    window.ieVer = ieVer;
    window.isAtLeastIE11 = isAtLeastIE11;

    if (ieVer === 9) {
        if (!(window.console && console.log)) {
            console = { // eslint-disable-line
                log: function () {},
                debug: function () {},
                info: function () {},
                warn: function () {},
                error: function () {}
            };
        }
    }

    /* GET SCROLLBAR WIDTH */
    function getScrollbarWidth () {
        if (ieVer < 9 && ieVer > 0) {
            return 18;
        } else {
            var $inner = $('<div style="width: 100%; height:200px;">test</div>'),
                $outer = $('<div style="width:200px;height:150px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),
                inner = $inner[0],
                outer = $outer[0],
                width1,
                width2;

            $('body').append(outer);
            width1 = inner.offsetWidth;
            $outer.css('overflow', 'scroll');
            width2 = outer.clientWidth;
            $outer.remove();

            return (width1 - width2);
        }
    }

    /* float table header */
    $.fn.floatTableHeader = function (update) {
        var table = $(this),
            thead = table.children('thead'),
            th = thead.find('tr:last-child th'),
            // widget = table.closest('.widget'),
            scroll = table.closest('.scroll-table'),
            scrollbarWidth = getScrollbarWidth(),
            // newThead = thead.clone(true),
            offsetTop,
            newTable;

        if (scroll.siblings('.floated-header').length !== 0) {
            newTable = scroll.siblings('.floated-header');
        } else {
            newTable = $('<div class="floated-header"><table><thead></thead></table></div>');
            newTable.find('thead').append(thead.clone(true));
            newTable.insertAfter(scroll);
        }

        if (!newTable.hasClass('init')) {
            newTable.addClass('init');
        } else {
            update = true;
        }

        function setScrollLeft () {
            var floatedTable = newTable.children('table'),
                scroll = table.closest('.scroll-table'),
                scrollLeft = scroll.scrollLeft();

            if (parseInt(floatedTable.css('left'), 10) !== -scrollLeft) {

                newTable.children('table').css({
                    left: '-' + scrollLeft + 'px'
                });
            }
        }

        function sizeHeaders () {
            var thead = newTable.find('thead'),
                colgroup = newTable.find('colgroup');

            newTable.width(scroll.width() - scrollbarWidth)
                .children('table').width(table.width())
                .height(thead.height());


            colgroup.children('col').each(function (index) {
                $(this).css({
                    'width': $(th[index]).outerWidth() + 'px'
                });
            });
        }

        offsetTop = 0;

        newTable.css({
            top: offsetTop
        });

        sizeHeaders();
        setScrollLeft();

        if (update === undefined) {
            scroll.scroll(function () {
                setScrollLeft();
            });

            // TODO: get scrolling to work when cursor is on TH.
            // newTable.on('mousewheel DOMMouseScroll', function (e) {
            //     var newEvent = new WheelEvent(e.originalEvent.type, e.originalEvent);

            //     scroll.get(0).dispatchEvent(newEvent);
            // });

            $(window).resize(sizeHeaders);
        }
    };

    function getTextWidth (text, font) {
        // re-use canvas object for better performance
        var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas')),
            context = canvas.getContext('2d'),
            metrics,
            textWidth = 0,
            i;

        context.font = font;

        // if multi-line text
        if (text.indexOf('\n') !== -1) {
            text = text.split('\n');

            // find longest line
            for (i = 0; i < text.length; i++) {
                metrics = context.measureText(text[i]);

                if (metrics.width > textWidth) {
                    textWidth = metrics.width;
                }
            }
        } else {
            metrics = context.measureText(text);
            textWidth = metrics.width;
        }

        return textWidth;
    }

    window.getTextWidth = getTextWidth;

    function buildReportList (data) {
        var list = [],
            reportIdList = [];

        function getReports (object) {
            var i,
                i2;

            for (i = 0; i < object.length; i++) {
                if (object[i].Reports.length !== 0) {
                    for (i2 = 0; i2 < object[i].Reports.length; i2++) {
                        // check to only add unique report ids
                        if (reportIdList.indexOf(object[i].Reports[i2].ReportId) === -1) {
                            reportIdList.push(object[i].Reports[i2].ReportId);

                            list.push({
                                ReportId: object[i].Reports[i2].ReportId,
                                ReportName: object[i].Reports[i2].ReportName
                            });
                        }
                    }
                }

                if (object[i].Categories.length !== 0) {
                    getReports(object[i].Categories);
                }
            }
        }

        getReports(data);

        return list;
    }

    window.buildReportList = buildReportList;

    function getAllReportNumbers (data) {
        var reportList = buildReportList(data),
            list = [],
            i;

        for (i = 0; i < reportList.length; i++) {
            // check to only add unique report ids
            if (list.indexOf(reportList[i].ReportId) === -1) {
                list.push(reportList[i].ReportId);
            }
        }

        return list;
    }

    window.getAllReportNumbers = getAllReportNumbers;

    /* document ready */
    // $(function () {

    // }); // END DOCUMENT READY
})();
