<template>
    <div id="dashboard-view" class="row" :class="{'is-widget-loading':pageLoader}" ref="dashboardView">
        <div class="report-section-header">
            <h1 class="report-name ms-0" :class="{'mb-2': isLRPCategory}">{{activeCategoryName}} </h1>
            <h3 class="d-inline ms-3" v-if="isExtramural"><a href data-bs-toggle="modal" data-bs-target="#about-lrp-modal"><i class="fas fa-info-circle"></i><span class="sr-only">Info</span></a></h3>
            <div id="dashboard-loader" class="loader widget-loader"
            v-if="pageLoader">Loading...</div>
            <div class="float-end me-3 my-3" v-if="LRPFilters && LRPFilters.length >= LRPLookupURL.length && isLRPCategory">
                <LRP-filters :generalFilters="true" :availableFilters="availableFilters" :isExtramuralCategory="isExtramural"></LRP-filters>
            </div>
            <vue-markdown :source="activeCategoryIntroText" :postrender="markdownCleanup" class="report-intro-text" v-if="activeCategoryIntroText"></vue-markdown>
        </div>
        <div class="col-lg-6" v-if="!$parent.isMobile">
            <template v-for="(widget, index) in orderedLayout"
                :key="'widget-' + index" > 
                <report  v-if="index % 2 === 0"  :isExtramuralCategory="isExtramural" :LRPReport="activeCategoryReports.filter(r => r.ReportId == widget).length > 0 ? activeCategoryReports.filter(r => r.ReportId == widget)[0].LRPReport : false"
                    :reportId="widget">
                </report>
            </template>
        </div>

        <div class="col-lg-6" v-if="!$parent.isMobile">
            <template v-for="(widget, index) in orderedLayout"
                :key="'widget-' + index" > 
                <report :reportId="widget" v-if="index % 2 !== 0"  :isExtramuralCategory="isExtramural" :LRPReport="activeCategoryReports.filter(r => r.ReportId == widget).length > 0 ? activeCategoryReports.filter(r => r.ReportId == widget)[0].LRPReport : false">
                </report>
            </template>
        </div>
        <div class="col-12" v-if="$parent.isMobile">
            <report
                v-for="(widget, index) in orderedLayout"
                :key="'widget-' + index" :reportId="widget" :isExtramuralCategory="isExtramural" :LRPReport="activeCategoryReports.filter(r => r.ReportId == widget).length > 0 ? activeCategoryReports.filter(r => r.ReportId == widget)[0].LRPReport : false">
            </report>
        </div>

        <div class="modal fade" id="about-lrp-modal" tabindex="-1" role="dialog" aria-hidden="true" v-if="!$route.params.id  && isLRPCategory" >
            <div class="modal-dialog" role="document" style="width:65%">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title">About the LRP Dashboard</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" id="report-modal">
                        <about-LRP></about-LRP>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Report from '@/components/Report';
import VueMarkdown from 'vue3-markdown-it';
import bus from '@/js/event-bus.js';
import LRPFilters from '@/components/LRPFilters';
import AboutLRP from '@/components/AboutLRP';

export default {
    name: 'Dashboard',
    components: {
    Report,
    VueMarkdown,
    LRPFilters,
    AboutLRP,
    AboutLRP
},
    computed: {
        ...mapGetters([
            'categoryList',
            'LRPFilters',
            'LRPLookupURL'
        ])
    },
    data () {
        return {
            orderedLayout: [],
            pageLoader: false,
            reportList: [],
            reportsLoaded: 0,
            activeCategoryName: null,
            activeCategoryId: null,
            activeCategoryIntroText: null,
            activeCategoryReports:null,
            availableFilters: [],
            isLRPCategory: false,
            isExtramural: true
        };
    },
    mounted () {
        let $this = this;
        
        bus.$on('dashboard:categoryId', function (catId) {
            $this.setReportIds(catId);
        })
    },
    created () {
        bus.$off('set:categoryId');
        bus.$off('lrpreport:update');
        bus.$off('lrpreport:updateAllReports');
        bus.$on('lrpreport:updateAllReports', () => {
            this.setReportIds(this.activeCategoryId);
        });
        bus.$on('set:categoryId', (id) => {
            this.setReportIds(id);
        });

    },
    methods: {
        setCategoryData (id) {
            var checkCategories = (list) => {
                var i;

                for (i = 0; i < list.length; i++) {
                    if (list[i].CategoryId === Number(id)) {
                        this.activeCategoryName = list[i].CategoryName;
                        this.activeCategoryId = list[i].CategoryId;
                        this.activeCategoryReports = list[i].Reports;
                        this.isLRPCategory = list[i].Reports[0].LRPReport ? list[i].Reports[0].LRPReport : false
                        this.isExtramural = this.isLRPCategory && list[i].CategoryName.toLowerCase().indexOf('intramural') === -1 ? true : false
                        if (list[i].IntroText) {
                            this.activeCategoryIntroText = list[i].IntroText;
                        } else {
                            this.activeCategoryIntroText = null;
                        }

                        return false;
                    }

                    if (list[i].Categories.length !== 0) {
                        checkCategories(list[i].Categories);
                    }
                }
            };

            bus.$off('lrpreport:update');
            checkCategories(this.categoryList);
        },
        setReportIds (catId, retainCategoryData) {
            function getOrderedLayout (categoryList) {
                var i,
                    subCategories;

                for (i = 0; i < categoryList.length; i++) {
                    if (categoryList[i].CategoryId === catId) {
                        return categoryList[i].Sortorder;
                    }

                    if (!!categoryList[i].Categories && categoryList[i].Categories.length !== 0) {
                        subCategories = getOrderedLayout(categoryList[i].Categories);

                        if (!!subCategories) {
                            return subCategories;
                        }
                    }
                }

                return false;
            }

            this.pageLoader = true;
            this.orderedLayout = [];
            this.reportsLoaded = 0;

            // convert to number for parsing from URL
            catId = Number(catId);
            //        /\_/\
            //       ( o.o )
            //        > ^ <
            if (!retainCategoryData) {
                this.setCategoryData(catId);
            }

            this.$nextTick(() => {
                // must be in nextTick after setCategoryData
                this.orderedLayout = getOrderedLayout(this.categoryList);
                if (this.orderedLayout && this.orderedLayout.length == 0) {
                    this.pageLoader = false;
                }
                this.$parent.scrollToTop();
            });
        },
        widgetLoaded () {
            this.reportsLoaded++;

            if (this.reportsLoaded >= this.orderedLayout.length) {
                this.pageLoader = false;
            }
        },
        markdownCleanup (text) {
            return window.markdownCleanup(text);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$loaderBackgroundColor: #ccc;
$loaderColor: #0f3068;

.table-loader {

    border-top: 1.1em solid $loaderBackgroundColor;
    border-right: 1.1em solid $loaderBackgroundColor;
    border-bottom: 1.1em solid $loaderBackgroundColor;
    z-index: 100;
    text-indent: 0;
    color: transparent;
}
.loader {
    position: absolute;
}
.loader {
    color: #cecece;
    font-size: 3px;
    left: 60%;
    top: 35%;
    text-indent: -9999em;
    border-top: 1.1em solid $loaderBackgroundColor;
    border-right: 1.1em solid $loaderBackgroundColor;
    border-bottom: 1.1em solid $loaderBackgroundColor;
    border-left: 1.1em solid $loaderColor;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 1;
    overflow: hidden;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
</style>
