<template>
    <div id="footer">
        <ul>
            <li><a :href="reportBaseURL + '/about'" title="About RePORT" rel="noopener">About RePORT</a> | </li>
            <li><a :href="reportBaseURL + '/faqs'" title="FAQs" rel="noopener">FAQs</a> | </li>
            <li><a :href="reportBaseURL + '/reportglossary'" title="Glossary" rel="noopener">Glossary</a> | </li>
            <li><a :href="reportBaseURL + '/contactus'" title="Contact Us" rel="noopener">Contact Us</a> | </li>
            <li><a :href="reportBaseURL + '/sitemap'" title="Site Map" rel="noopener">Site Map</a> | </li>
            <li><a href="/sites/report/files/2020-07/DataAccessPolicy.pdf" title="Data Access Policy" rel="noopener">Data Access Policy</a> | </li>
            <li><a :href="reportBaseURL + '/accessibility-statement'" title="Accessibility Statement" rel="noopener">Accessibility Statement</a> | </li>
            <li><a href="https://grants.nih.gov/grants/privacy.htm" title="Privacy Statement" rel="noopener">Privacy Statement</a> | </li>
            <li><a href="https://grants.nih.gov/grants/disclaimer.htm" title="Disclaimer" rel="noopener">Disclaimer</a> | </li>
            <li><a href="https://www.nih.gov/icd/od/foia/index.htm" title="FOIA" rel="noopener">FOIA</a> | </li>
            <li><a href="https://grants.nih.gov/grants/edocs.htm" title="Help Downloading Files" rel="noopener">Help Downloading Files</a> | </li>
            <li><a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" title="Vulnerability Disclosure Policy" rel="noopener">HHS Vulnerability Disclosure</a></li>
        </ul>

        <ul>
            <li><a href="https://www.grants.nih.gov/" title="Office of Extramural Research" rel="noopener">Office of Extramural Research</a> | </li>
            <li><a href="https://www.nih.gov/" title="National Institutes of Health" rel="noopener">National Institutes of Health</a> | </li>
            <li><a href="https://www.hhs.gov/" title="U.S. Department of Health and Human Services" rel="noopener">U.S. Department of Health and Human Services</a> | </li>
            <li><a href="https://www.usa.gov/" title="USA.gov - Government Made Easy" rel="noopener">USA.gov - Government Made Easy</a> | </li>
            <li><a href="https://www.grants.gov/" title="Grants.gov" rel="noopener">Grants.gov</a></li>
        </ul>

        <div class="footer-tagline">
            <span>NIH...<em>Turning Discovery Into Health<sup>&reg;</sup></em></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
            baseURL: window.baseURL,
            reportBaseURL: window.location.origin
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
