<template>
    <div id="single-report-view">
        <report
            :reportId="$route.params.id"
            :miniMode="true"
            :embeded="true"
            ref="report"
        ></report>
    </div>
</template>

<script>
import Report from '@/components/Report';
export default {
    name: 'ReportEmbed',
    components: {
        Report
    },
    data () {
        return {
            // msg: 'Welcome to Your Vue.js App'
        };
    },
    mounted () {
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
